body {
  background: rgb(20, 20, 20);
}

.App {
  text-align: center;
  min-height: 100vh;
}

@keyframes App-logo-spin {
  0% {
    transform: rotate(0deg);
    /* opacity: 0; */
  }
  25% {
    transform: rotate(5deg);
    /* opacity: 0.2; */
  }
  50% {
    transform: rotate(0deg);
    /* opacity: 1; */
  }
  75% {
    transform: rotate(-5deg);
    /* opacity: 0.2; */
  }
  100% {
    transform: rotate(0deg);
    /* opacity: 0; */
  }
}

@keyframes logo-fade-in {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}
